const ArgumentType = require('../../extension-support/argument-type');
const BlockType = require('../../extension-support/block-type');
// const TragetType = require('../../extension-support/target-type');
const Cast = require('../../util/cast');
const uid = require('../../util/uid');
// const log = require('../../util/log');
const Variable = require('../../engine/variable');



// const formatMessage = require('format-message');

class Scratch3AdoScriptEx {

    constructor(runtime) {
        this.runtime = runtime;

        //TODO figure out how "this" relates to the different targets. Is there one "Scratch3AdoScriptEx this" for each target or one for all targets? do the targets get in the way of each other then?

        // ado_IF-ELSIF-ELSE variables
        this.checkifelse = false;
        this.ifparentcondition = false;

        // ado_PROCEDURE variables
        this.procedures = [];
        this.call_ProcedureName = '';
        this.call_Procedure = false;

        // ado_FUNCTION variables
        this.functions = [];
        this.call_FunctionName = '';
        this.call_Function = false;

        // ado_SET variables
        //TODO Instead of this.variables the code should use util.target.lookupVariableByNameAndType, util.target.createVariable etc.
        this.variables = [];

        // return variables
        //TODO Instead of this.returns the code should use util.target.lookupVariableByNameAndType, util.target.createVariable etc.
        this.returns = [];

        // code generator
        // Stores the generated code and controls whether code is generated or the elements are just parsed to update certain aspects (available variables).
        this.gen_code = [];
        this.gen = false;

        // For handling indentation
        this.indentationLevel = 0;
        this.indentationCharacters = '  '; // Default is two spaces.

        // Didn't seem to be used.
        //this.loop = 0;
    }


    // PB: added indentation handling, so that generated code is more readable and beginners learn to write readable code.
    //TODO this could maybe be changed to use util.stackFrame.index instead of its own counter, but I don't know what util.stackFrame.index really is (seems to be tied to "what frame are we animating"?!?)
    getIndentation() {
        return this.indentationCharacters.repeat(this.indentationLevel);
    }
    increaseIndentationAfter() {
        const currentIndentation = this.getIndentation();
        this.indentationLevel++;
        return currentIndentation;
    }
    decreaseIndentation() {
        // Using max function to prevent going below 0;
        this.indentationLevel = Math.max(this.indentationLevel - 1, 0);
        return this.getIndentation();
    }


    /**
     * Returns the metadata about your extension.
     */
    getInfo() {
        return {
            // unique ID for your extension
            id: 'AdoScriptEx',

            // name that will be displayed in the Scratch UI
            name: 'AdoScript Extension',

            // your Scratch blocks
            blocks: [
                {
                    opcode: 'ado_set_variables_SETL',
                    blockType: BlockType.COMMAND,
                    text: 'SETL [VARNAME]:([VAL])',
                    arguments: {
                        VARNAME: {
                            type: ArgumentType.STRING,
                            defaultValue: 'NewVARIABLE'
                        },
                        VAL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'anyValue'
                        }
                    }
                },

                {
                    opcode: 'ado_get_variables',
                    blockType: BlockType.REPORTER,
                    text: '[VARNAME]',
                    disableMonitor: true,
                    arguments: {
                        VARNAME: {
                            type: ArgumentType.STRING,
                            menu: 'variablesMenu'
                        }
                    }
                },

                // JS: 1차 완
                {
                    opcode: 'ado_FOR_Numeric',
                    blockType: BlockType.LOOP,
                    text: 'FOR [VAR] from:[FROM] to:[TO] by:[BY]',
                    arguments: {
                        VAR: {
                            type: ArgumentType.STRING,
                            defaultValue: 'i'
                        },
                        FROM: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        },
                        TO: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 10
                        },
                        BY: {
                            type: ArgumentType.NUMBER,
                            defaultValue: 1
                        }
                    }
                },

                // JS: 1차 완
                {
                    opcode: 'ado_FOR_Tokens',
                    blockType: BlockType.LOOP,
                    text: 'FOR [VAR] in:[STR] sep:[SEP]',
                    arguments: {
                        VAR: {
                            type: ArgumentType.STRING,
                            defaultValue: 'item'
                        },
                        STR: {
                            type: ArgumentType.STRING,
                            defaultValue: '"a b c"'
                        },
                        SEP: {
                            type: ArgumentType.STRING,
                            defaultValue: ' '
                        }
                    }
                },

                {
                    opcode: 'ado_BOOLEAN_Logical_NOT',
                    blockType: BlockType.BOOLEAN,
                    text: 'NOT [OPERAND]',
                    arguments: {
                        OPERAND: {
                            type: ArgumentType.STRING,
                            defaultValue: ' '
                        }
                    }
                },

                {
                    opcode: 'ado_BOOLEAN_Logical_OR_AND',
                    blockType: BlockType.BOOLEAN,
                    text: '[OPERAND1] [OPERATOR] [OPERAND2]',
                    arguments: {
                        OPERAND1: {
                            type: ArgumentType.STRING,
                            defaultValue: ' '
                        },
                        OPERATOR: {
                            type: ArgumentType.STRING,
                            menu: 'Logical_Operators_Menu'
                        },
                        OPERAND2: {
                            type: ArgumentType.STRING,
                            defaultValue: ' '
                        }
                    }
                },

                {
                    opcode: 'ado_BOOLEAN_Comparison',
                    blockType: BlockType.BOOLEAN,
                    text: '[OPERAND1] [OPERATOR] [OPERAND2]',
                    arguments: {
                        OPERAND1: {
                            type: ArgumentType.STRING,
                            defaultValue: ' '
                        },
                        OPERATOR: {
                            type: ArgumentType.STRING,
                            menu: 'Comparison_Operators_Menu'
                        },
                        OPERAND2: {
                            type: ArgumentType.STRING,
                            defaultValue: ' '
                        }
                    }
                },

                // JS: 1차 완
                {
                    opcode: 'ado_WHILE',
                    blockType: BlockType.LOOP,
                    text: 'WHILE [CONDITION]',
                    arguments: {
                        CONDITION: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: false
                        }
                    },
                    branchCount: 1
                },

                // PB: IF, ELSIF and ELSE disabled, as their code generation doesn't work.
                //TODO fix code generation for IF, ELSIF and ELSE.
                /*
                // JS 1차 완
                {
                    opcode: 'ado_IF',
                    blockType: BlockType.CONDITIONAL,
                    text: 'IF [CONDITION]',
                    arguments: {
                        CONDITION: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: false
                        }
                    },
                    branchCount: 1
                },

                // JS 1차 완
                {
                    opcode: 'ado_ELSIF',
                    blockType: BlockType.CONDITIONAL,
                    text: 'ELSIF [CONDITION]',
                    arguments: {
                        CONDITION: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: false
                        }
                    },
                    branchCount: 1
                },

                // JS 1차 완
                {
                    opcode: 'ado_ELSE',
                    blockType: BlockType.CONDITIONAL,
                    text: 'ELSE',
                    branchCount: 1
                },
                */

                // JS: 1차 완
                {
                    opcode: 'ado_EXIT',
                    blockType: BlockType.COMMAND,
                    text: 'EXIT'
                },

                // PB: Functions disabled as they don't generate the right code.
                //TODO Functions should define a function (local or global) that can then be used in expressions. If this is just supposed to generate blocks that are replaced by their content then it should use a different name, as a function has a specific meaning in AdoScript. (See also commend for the PROCEDURE blocks)
                /*
                // JS 1차 완
                {
                    opcode: 'ado_def_FUNCTION',
                    blockType: BlockType.HAT,
                    text: 'define FUNCTION [FUNCNAME]',
                    isEdgeActivated: false,
                    shouldRestartExistingThreads: true,
                    arguments: {
                        FUNCNAME: {
                            type: ArgumentType.STRING,
                            menu: 'functionsMenu'
                        }
                    }
                },

                // JS 1차 완
                {
                    opcode: 'ado_call_FUNCTION',
                    blockType: BlockType.COMMAND,
                    text: 'FUNCTION [FUNCNAME]',
                    arguments: {
                        FUNCNAME: {
                            type: ArgumentType.STRING,
                            menu: 'functionsMenu'
                        }
                    }
                },
                */

                // PB: Procedures disabled as they don't generate the right code.
                //TODO Procedures should define a procedure (local or global) that can then be used like an AdoScript command. This generates blocks that are replaced by their content instead. If that is the goal, then it should use a different name, as a procedure has a specific meaning in AdoScript.
                /*
                // JS 1차 완
                {
                    opcode: 'ado_def_PROCEDURE',
                    blockType: BlockType.HAT,
                    text: 'define PROCEDURE [PROCNAME]',
                    isEdgeActivated: false,
                    shouldRestartExistingThreads: true,
                    arguments: {
                        PROCNAME: {
                            type: ArgumentType.STRING,
                            menu: 'proceduresMenu'
                        }

                    }
                },

                // JS 1차 완
                {
                    opcode: 'ado_call_PROCEDURE',
                    blockType: BlockType.COMMAND,
                    text: 'PROCEDURE [PROCNAME]',
                    arguments: {
                        PROCNAME: {
                            type: ArgumentType.STRING,
                            menu: 'proceduresMenu'
                        }
                    }
                },
                */

                {
                    opcode: 'ado_get_RETURN',
                    blockType: BlockType.REPORTER,
                    text: '[RETURNNAME]',
                    disableMonitor: true,
                    arguments: {
                        RETURNNAME: {
                            type: ArgumentType.STRING,
                            menu: 'returnsMenu'
                        }
                    }
                },

                {
                    opcode: 'ado_GET_ACT_MODEL',
                    blockType: BlockType.COMMAND,
                    text: 'CC "Modeling" GET_ACT_MODEL'
                },

                {
                    opcode: 'ado_GET_ALL_OBJS_OF_CLASSNAME',
                    blockType: BlockType.COMMAND,
                    text: 'CC "Core"  GET_ALL_OBJS_OF_CLASSNAME modelid:([MODELID]) classname:([CLASSNAME])',
                    arguments: {
                        MODELID: {
                            type: ArgumentType.STRING,
                            defaultValue: 'MODELID'
                        },
                        CLASSNAME: {
                            type: ArgumentType.STRING,
                            defaultValue: 'CLASSNAME'
                        }
                    }
                },

                {
                    opcode: 'ado_GET_ATTR_VAL',
                    blockType: BlockType.COMMAND,
                    text: 'CC "Core" GET_ATTR_VAL objid:([OBJID]) attrname:([ATTRNAME])',
                    arguments: {
                        OBJID: {
                            type: ArgumentType.STRING,
                            defaultValue: 'OBJID'
                        },
                        ATTRNAME: {
                            type: ArgumentType.STRING,
                            defaultValue: 'ATTRNAME'
                        }
                    }
                },

                {
                    opcode: 'ado_INFOBOX',
                    blockType: BlockType.COMMAND,
                    text: 'CC "AdoScript" INFOBOX ([STRVAL])',
                    arguments: {
                        STRVAL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'STRVALUE'
                        }
                    }
                },

                {
                    opcode: 'ado_VIEWBOX',
                    blockType: BlockType.COMMAND,
                    text: 'CC "AdoScript" VIEWBOX text:([STRVAL])',
                    arguments: {
                        STRVAL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'STRVALUE'
                        }
                    }
                },

                {
                    opcode: 'ado_VAL',
                    blockType: BlockType.REPORTER,
                    text: 'VAL [STRVAL]',
                    arguments: {
                        STRVAL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'STRVAL'
                        }
                    }
                },

                {
                    opcode: 'ado_STR',
                    blockType: BlockType.REPORTER,
                    text: 'STR [STRVAL]',
                    arguments: {
                        STRVAL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'STRVAL'
                        }
                    }
                },

                //TODO this does not allow specifying the 2nd parameter (separator) for the function.
                {
                    opcode: 'ado_tokcnt',
                    blockType: BlockType.REPORTER,
                    text: 'tokcnt([SOURCE])',
                    arguments: {
                        SOURCE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'SOURCE'
                        }
                    }
                },

                {
                    opcode: 'ado_aappend',
                    blockType: BlockType.REPORTER,
                    text: 'aappend([ARRAY],[VAL])',
                    arguments: {
                        ARRAY: {
                            type: ArgumentType.STRING,
                            defaultValue: 'ARRAY'
                        },
                        VAL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'VAL'
                        }
                    }
                },

                {
                    opcode: 'ado_START_GEN',
                    blockType: BlockType.HAT,
                    text: 'START [ADOXX_IMG] AdoScript code Generate',
                    isEdgeActivated: false,
                    shouldRestartExistingThreads: true,
                    arguments: {
                        ADOXX_IMG: {
                            type: ArgumentType.IMAGE,
                            dataURI: 'https://www.adoxx.org/logo/adoxx_logo.png',
                        }
                    }
                },

                {
                    opcode: 'ado_GENCODE',
                    blockType: BlockType.COMMAND,
                    text: 'Generate AdoScript Code',
                    isTerminal: true
                },

                // added by muck
                // PB: Removed, as the sharing is being reworked.
                /*
                {
                    opcode: 'ado_GENCODE_PUBLIC',
                    bockType: BlockType.COMMAND,
                    text: 'Generate AdoScript Code in Repository'
                },
                */


                /*
                {
                    opcode: 'ado_BREAK',
                    blockType: BlockType.COMMAND,
                    text: 'BREAK'
                },

                {
                    opcode: 'ado_CALL',
                    blockType: BlockType.COMMAND,
                    text: 'CALL dll:[DLL] function:[FUNCTION]',
                    arguments: {
                        DLL: {
                            type: ArgumentType.STRING,
                            defaultValue: 'example.dll'
                        },
                        FUNCTION: {
                            type: ArgumentType.STRING,
                            defaultValue: 'exampleFunction'
                        }
                    }
                },

                {
                    opcode: 'ado_CC',
                    blockType: BlockType.COMMAND,
                    text: 'CC [PORT]',
                    arguments: {
                        PORT: {
                            type: ArgumentType.STRING,
                            defaultValue: 'portName'
                        }
                    }
                },

                {
                    opcode: 'ado_EXECUTE',
                    blockType: BlockType.COMMAND,
                    text: 'EXECUTE [SOURCE]',
                    arguments: {
                        SOURCE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'sourceCode or file path'
                        }
                    }
                },

                {
                    opcode: 'ado_LEO',
                    blockType: BlockType.COMMAND,
                    text: 'LEO parse [STR] [COMMANDS]',
                    arguments: {
                        STR: {
                            type: ArgumentType.STRING,
                            defaultValue: 'input string'
                        },
                        COMMANDS: {
                            type: ArgumentType.STRING,
                            defaultValue: 'commands'
                        }
                    }
                },

                {
                    opcode: 'ado_NEXT',
                    blockType: BlockType.COMMAND,
                    text: 'NEXT'
                },

                {
                    opcode: 'ado_SEND',
                    blockType: BlockType.COMMAND,
                    text: 'SEND [MESSAGE] to:[PORT] answer:[ANSWER]',
                    arguments: {
                        MESSAGE: {
                            type: ArgumentType.STRING,
                            defaultValue: 'Hello'
                        },
                        PORT: {
                            type: ArgumentType.STRING,
                            defaultValue: 'portName'
                        },
                        ANSWER: {
                            type: ArgumentType.STRING,
                            defaultValue: 'response'
                        }
                    }
                },

                {
                    opcode: 'ado_START',
                    blockType: BlockType.COMMAND,
                    text: 'START [APP] cmdshow:[CMD_SHOW]',
                    arguments: {
                        APP: {
                            type: ArgumentType.STRING,
                            defaultValue: 'notepad.exe'
                        },
                        CMD_SHOW: {
                            type: ArgumentType.STRING,
                            defaultValue: 'shownormal',
                        }
                    }
                },

                {
                    opcode: 'ado_SYSTEM',
                    blockType: BlockType.COMMAND,
                    text: 'SYSTEM [CMD] [with-console-window] [hide] result:[RESULT]',
                    arguments: {
                        CMD: {
                            type: ArgumentType.STRING,
                            defaultValue: 'echo Hello, world!'
                        },
                        WITH_CONSOLE_WINDOW: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: false
                        },
                        HIDE: {
                            type: ArgumentType.BOOLEAN,
                            defaultValue: false
                        },
                        RESULT: {
                            type: ArgumentType.STRING,
                            defaultValue: 'resultVar'
                        }
                    }
                },
                */
            ],

            menus: {
                variablesMenu: {
                    items: this._getVariablesMenu.bind(this)
                },
                functionsMenu: {
                    items: this._getFunctionsMenu.bind(this)
                },
                proceduresMenu: {
                    items: this._getProceduresMenu.bind(this)
                },
                returnsMenu: {
                    items: this._getReturnsMenu.bind(this)
                },
                Comparison_Operators_Menu: {
                    items: ['<', '<=', '=', '<>', '>=', '>']
                },
                Logical_Operators_Menu: {
                    items: ['OR', 'AND']
                }
            }
        };
    }



    /*
     * helper functions.
     */



    // Checks if a variable is already in the extension's variable list and if it isn't there then it adds it.
    // Returns true if the variable was missing and has been added, otherwise false. Variables are identified by a unique id.
    __addVariableIfMissing(varId, varName) {
        if (this.variables.find((element) => { return (element.id == varId); })) {
            return false;
        } else {
            this.variables.push({ id: varId, name: varName });
            return true;
        }
    }

    // Checks if a variable is available in both the "target" and the extension's variable list and adds it where it is missing.
    // Returns true if the variable was missing and has been added, otherwise false. Variables are identified by a unique id.
    __addVariableIfMissingTarget(target, varName, varValue) {
        const check_varialble = target.lookupVariableByNameAndType(varName, Variable.SCALAR_TYPE);
        if (!check_varialble) {
            const varID = uid();
            target.createVariable(varID, varName, Variable.SCALAR_TYPE, false);
            const currVar = target.lookupVariableByNameAndType(varName, Variable.SCALAR_TYPE);
            currVar.value = varValue;
            this.variables.push({ id: varID, name: varName });
            return true;
        } else {
            const currVAR = target.lookupVariableByNameAndType(varName, Variable.SCALAR_TYPE);
            currVAR.value = varValue;
            // PB: Fix for missing variables when loading projects from files.
            return this.__addVariableIfMissing(currVAR.id, currVAR.name);
        }
    }

    // Checks if a returns is already in the extension's returns list and if it isn't there then it adds it.
    // Returns true if the returns was missing and has been added, otherwise false. Returns are identified by their name.
    __addReturnIfMissing(varName) {
        if (!this.returns.find((element) => { return (element.name == varName); })) {
            this.returns.push({ name: varName });
            return true;
        } else {
            return false;
        }
    }

    // USed for code generation, building a Blob object for the described AdoScript.
    // Returns a Blob object with the generated code.
    __generateCode() {
        return new Blob([this.gen_code.join('\n')], {type:'text/plain;charset=UTF-8'});
    }



    /*
     * implementation of the block with the opcode that matches this name.
     * this will be called when the block is used.
     */



    // PB: Added simple check if variable name starts with a lower-case letter.
    ado_set_variables_SETL(args, util) {
        const target = util.target;

        //TODO what is this code block supposed to do?
        const init_Variable = target.lookupVariableByNameAndType(args.VARNAME, Variable.SCALAR_TYPE);
        if (!init_Variable) {
            target.createVariable(this.variables[0].id, this.variables[0].name, Variable.SCALAR_TYPE, false);
        }

        if (args.VARNAME === 'NewVARIABLE' ) {
            alert('Enter a VARIABLE name.');
            util.runtime.stopAll();
        } else if (args.VARNAME.charAt(0) !== args.VARNAME.charAt(0).toLowerCase()) {
            //TODO proper check if it is an allowed value for a variable name according to LEO.
            alert('VARIABLE names must start with a lower-case letter.');
            util.runtime.stopAll();
        } else {
            // PB: Fix (in function) for restoring variables when loading project file.
            this.__addVariableIfMissingTarget(target, args.VARNAME, args.VAL);
            if(this.gen === true) {
                this.gen_code.push(`${this.getIndentation()}SETL ${args.VARNAME}:(${args.VAL})`);
            }
        }
    }

    ado_get_variables(args, util) {
        if (args.VARNAME === 'New VARIABLE') {
            alert('Select a VARIABLE name.');
            util.runtime.stopAll();
        } else {
            //TODO what is the point of calling lookupVariableByNameAndType when just the argument is returned?
            const currVAR = util.target.lookupVariableByNameAndType(args.VARNAME, Variable.SCALAR_TYPE);
            // return currVAR.value;
            return args.VARNAME;
        }
    }

    _getVariablesMenu() {
        if (this.variables.length === 0) {
            const varID = uid();
            const varNAME = 'New VARIABLE';
            this.variables.push({ id: varID, name: varNAME });
        }
        return this.variables.map(variables => [variables.name, variables.name]);
    }

    // JS: 1차 완
    // PB: creating loop code instead of repeating the code. Also removed unused variables.
    ado_FOR_Numeric(args, util) {

        if (typeof util.stackFrame.index === 'undefined') {
            util.stackFrame.index = 0;
        }

        // PB: Fix for loading variables from FOR loops.
        this.__addVariableIfMissingTarget(util.target, args.VAR, args.FROM);

        // If not generating code, then just process the content.
        //TODO why was this `&& util.stackFrame.index <= to`? what is stackFrame.index used for?
        //if (!this.gen && util.stackFrame.index <= to) {
        if (!this.gen) {
            // Process the inner stuff.
            //TODO why do we need util.stackFrame? Also in many other parts of the code.
            util.stackFrame.index++;
            util.startBranch(1, true);
        }

        // If generating code, then add the FOR loop syntax and put all the
        if (this.gen && util.stackFrame.index === 0) {
            if (args.BY == 1) {
                this.gen_code.push(`${this.increaseIndentationAfter()}FOR ${args.VAR} from:(${args.FROM}) to:(${args.TO}) {`);
            } else {
                this.gen_code.push(`${this.increaseIndentationAfter()}FOR ${args.VAR} from:(${args.FROM}) to:(${args.TO}) by:(${args.BY}) {`);
            }
            //TODO why do we need util.stackFrame? Also in many other parts of the code.
            util.stackFrame.index++;
            util.startBranch(1, true);
        }

        // PB: I don't know why, but if this is at the end of the previous IF it doesn't push the code properly.
        if (this.gen && util.stackFrame.index === 1) {
            this.gen_code.push(`${this.decreaseIndentation()}}`);
        }
    }

    // JS: 1차 완
    ado_FOR_Tokens(args, util) {
        if (typeof util.stackFrame.index === 'undefined') {
            util.stackFrame.index = 0;
        }

        // PB: Fix for loading variables from FOR loops.
        this.__addVariableIfMissingTarget(util.target, args.VAR, args.STR);

        //TODO why is this `&& util.stackFrame.index < items.length`? what is stackFrame.index used for?
        //if (!this.gen && util.stackFrame.index < items.length) {
        if (!this.gen) {
            util.stackFrame.index++;
            util.startBranch(1, true);
        }

        if (this.gen && util.stackFrame.index === 0) {
            if(args.SEP === ' ') {
                this.gen_code.push(`${this.increaseIndentationAfter()}FOR ${args.VAR} in:(${args.STR}) {`);
            } else {
                this.gen_code.push(`${this.increaseIndentationAfter()}FOR ${args.VAR} in:(${args.STR}) sep:(${args.SEP}) {`);
            }
            util.stackFrame.index++;
            util.startBranch(1, true);
        }

        // PB: I don't know why, but if this is at the end of the previous IF it doesn't push the code properly.
        if (this.gen && util.stackFrame.index === 1) {
            this.gen_code.push(`${this.decreaseIndentation()}}`);
        }
    }

    ado_BOOLEAN_Logical_OR_AND(args, util) {
        return `(${args.OPERAND1} ${args.OPERATOR} ${args.OPERAND2})`;
    }

    ado_BOOLEAN_Logical_NOT(args, util) {
        return `NOT ${args.OPERAND}`;
    }

    ado_BOOLEAN_Comparison(args, util) {
        return `(${args.OPERAND1} ${args.OPERATOR} ${args.OPERAND2})`;
    }

    // JS: 1차 완
    ado_WHILE(args, util) {
        const condition = Cast.toBoolean(args.CONDITION);

        if (!this.gen && condition) {
            util.startBranch(1, true);
        }

        if (typeof util.stackFrame.index === 'undefined') {
            util.stackFrame.index = 0;
        }

        if (this.gen && util.stackFrame.index === 0) {
            this.gen_code.push(`${this.increaseIndentationAfter()}WHILE (${args.CONDITION}) {`);
            util.stackFrame.index++;
            util.startBranch(1, true);
        }

        // PB: I don't know why, but if this is at the end of the previous IF it doesn't push the code properly.    
        if (this.gen && util.stackFrame.index === 1) {
            this.gen_code.push(`${this.decreaseIndentation()}}`);
        }
    }

    // JS: 1차 완
    //TODO this doesn't generate the code it should.
    ado_IF(args, util) {
        const target = util.target;
        const thread = util.thread;
        const currBlockID = thread.peekStack();

        const nextBlockId = target.blocks.getNextBlock(currBlockID);
        const nextBlock = target.blocks.getBlock(nextBlockId);

        const condition = Cast.toBoolean(args.CONDITION);
        this.checkifelse = false;
        this.ifparentcondition = false;

        if (!nextBlock) {
            this.checkifelse = false;
            if (condition) {
                util.startBranch(1, false);
                this.ifparentcondition = false;
            }
            return;
        }

        // const nextBlockOpcode = nextBlock.opcode;
        const nextBlockOpcode = target.blocks.getOpcode(nextBlock);

        if (nextBlockOpcode == 'AdoScriptEx_ado_ELSIF' || nextBlockOpcode == 'AdoScriptEx_ado_ELSE') {
            this.checkifelse = true;
            if (!this.ifparentcondition && condition) {
                util.startBranch(1, false);
                this.ifparentcondition = condition;
            }
        } else {
            this.checkifelse = false;
            if (!this.ifparentcondition && condition) {
                util.startBranch(1, false);
                this.ifparentcondition = false;
            }
        }
    }

    // JS 1차 완
    //TODO this doesn't generate the code it should.
    ado_ELSIF(args, util) {
        if (!this.checkifelse) {
            return;
        }

        const target = util.target;
        const thread = util.thread;
        const currBlockID = thread.peekStack();

        const nextBlockId = target.blocks.getNextBlock(currBlockID);
        const nextBlock = target.blocks.getBlock(nextBlockId);

        const condition = Cast.toBoolean(args.CONDITION);

        if (this.checkifelse && this.ifparentcondition && !nextBlock) {
            this.checkifelse = false;
            this.ifparentcondition = false;
            return;
        }

        if (!nextBlock) {
            this.checkifelse = false;
            if (condition) {
                util.startBranch(1, false);
                this.ifparentcondition = false;
            }
            return;
        }

        // const nextBlockOpcode = nextBlock.opcode;
        const nextBlockOpcode = target.blocks.getOpcode(nextBlock);

        if (nextBlockOpcode == 'AdoScriptEx_ado_ELSIF' || nextBlockOpcode == 'AdoScriptEx_ado_ELSE') {
            this.checkifelse = true;

            if (!this.ifparentcondition && condition) {
                util.startBranch(1, false);
                this.ifparentcondition = condition;
            }
        } else {
            this.checkifelse = false;
            if (!this.ifparentcondition && condition) {
                util.startBranch(1, false);
                this.ifparentcondition = false;
            }
        }
    }

    // JS 1차 완
    //TODO this doesn't generate the code it should.
    ado_ELSE(args, util) {
        if (!this.checkifelse) {
            return;
        }

        if (this.checkifelse && this.ifparentcondition)
        {
            this.checkifelse = false;
            this.ifparentcondition = false;
            return;
        }

        if (this.checkifelse && !this.ifparentcondition) {
            this.checkifelse = false;
            util.startBranch(1, false);
            this.ifparentcondition = false;
            return;
        }
    }

    // JS: 1차 완
    ado_EXIT(args, util) {
        this.gen_code.push(`${this.getIndentation()}EXIT`);
    }

    // JS 2차 완: 파라미터 관련 개발 필요
    //TODO This does not generate the code it should. Functions should define a function (local or global) that can then be used in expressions. If this is just supposed to generate blocks that are replaced by their content then it should use a different name, as a function has a specific meaning in AdoScript.
    ado_def_FUNCTION(args, util) {
        const target = util.target;

        const init_NewFunc = target.lookupVariableByNameAndType(args.FUNCNAME, Variable.BROADCAST_MESSAGE_TYPE);
        if (!init_NewFunc) {
            target.createVariable(this.functions[0].id, this.functions[0].name, Variable.BROADCAST_MESSAGE_TYPE, false);
        }

        if (args.FUNCNAME === 'New FUNCTION') {
            const funcNAME = prompt('Enter New FUNCTION name:');

            if (funcNAME) {
                const check_Function = target.lookupVariableByNameAndType(funcNAME, Variable.BROADCAST_MESSAGE_TYPE);
                if (!check_Function) {
                    const funcID = uid();

                    target.createVariable(funcID, funcNAME, Variable.BROADCAST_MESSAGE_TYPE, false);
                    this.functions.push({ id: funcID, name: funcNAME });
                    alert(`FUNCTIONS "${funcNAME}" created.`);
                } else {
                    alert(`FUNCTIONS "${funcNAME}" already exists.`);
                }
            }
            return false;
        }

        const currFUNC = target.lookupVariableByNameAndType(args.FUNCNAME, Variable.BROADCAST_MESSAGE_TYPE);

        if (currFUNC.name === this.call_FunctionName) {
            this.call_FunctionName = '';
            return true;
        }
    }

    // JS 2차 완: 파라미터 관련 개발 필요
    //TODO This does not generate the code it should. Functions should define a function (local or global) that can then be used in expressions. If this is just supposed to generate blocks that are replaced by their content then it should use a different name, as a function has a specific meaning in AdoScript.
    ado_call_FUNCTION(args, util) {
        if (args.FUNCNAME === 'New FUNCTION') {
            alert('Select a FUNCTION name.');
            util.runtime.stopAll();
        } else {
            if (!util.stackFrame.startedThreads) {
                this.call_FunctionName = args.FUNCNAME;
                util.stackFrame.startedThreads = util.startHats('AdoScriptEx_ado_def_FUNCTION');
            }

            const instance = this;
            const waiting = util.stackFrame.startedThreads
                    .some(thread => instance.runtime.threads.indexOf(thread) !== -1);

            if (waiting) {
                util.yield();
            }
        }
    }

    // JS 1차 완
    _getFunctionsMenu() {
        if (this.functions.length === 0) {
            const funcID = uid();
            const funcNAME = 'New FUNCTION';

            this.functions.push({ id: funcID, name: funcNAME });
        }
        return this.functions.map(functions => [functions.name, functions.name]);
    }

    // JS 2차 완: 파라미터 관련 개발 필요
    //TODO This does not generate the code it should. Procedures should define a procedure (local or global) that can then be used like an AdoScript command. This generates blocks that are replaced by their content instead. If that is the goal, then it should use a different name, as a procedure has a specific meaning in AdoScript.
    ado_def_PROCEDURE (args, util) {
        const target = util.target;

        const init_NewProc = target.lookupVariableByNameAndType(args.PROCNAME, Variable.BROADCAST_MESSAGE_TYPE);
        if (!init_NewProc) {
            target.createVariable(this.procedures[0].id, this.procedures[0].name, Variable.BROADCAST_MESSAGE_TYPE, false);
        }

        if (args.PROCNAME === 'New PROCEDURE') {
            const procNAME = prompt('Enter New PROCEDURE name:');

            if (procNAME) {
                const check_Procedure = target.lookupVariableByNameAndType(procNAME, Variable.BROADCAST_MESSAGE_TYPE);
                if (!check_Procedure) {
                    const procID = uid();

                    target.createVariable(procID, procNAME, Variable.BROADCAST_MESSAGE_TYPE, false);
                    this.procedures.push({ id: procID, name: procNAME });
                    alert(`FUNCTIONS "${procNAME}" created.`);
                } else {
                    alert(`FUNCTIONS "${procNAME}" already exists.`);
                }
            }
            return false;
        }

        const currPROC = target.lookupVariableByNameAndType(args.PROCNAME, Variable.BROADCAST_MESSAGE_TYPE);

        if (currPROC.name === this.call_ProcedureName) {
            this.call_ProcedureName = '';
            return true;
        }
    }

    // JS 2차 완: 파라미터 관련 개발 필요
    //TODO This does not generate the code it should. Procedures should define a procedure (local or global) that can then be used like an AdoScript command. This generates blocks that are replaced by their content instead. If that is the goal, then it should use a different name, as a procedure has a specific meaning in AdoScript.
    ado_call_PROCEDURE(args, util) {
        if (args.PROCNAME === 'New PROCEDURE') {
            alert('Select a PROCEDURE name.');
            util.runtime.stopAll();
        } else {
            if (!util.stackFrame.startedThreads) {
                this.call_ProcedureName = args.PROCNAME;
                util.stackFrame.startedThreads = util.startHats('AdoScriptEx_ado_def_PROCEDURE');
            }

            const instance = this;
            const waiting = util.stackFrame.startedThreads
                    .some(thread => instance.runtime.threads.indexOf(thread) !== -1);

            if (waiting) {
                util.yield();
            }
        }
    }

    // JS 1차 완
    _getProceduresMenu() {
        if (this.procedures.length === 0) {
            const procID = uid();
            const procNAME = 'New PROCEDURE';

            this.procedures.push({ id: procID, name: procNAME });
        }
        return this.procedures.map(procedures => [procedures.name, procedures.name]);
    }

    ado_get_RETURN(args, util) {
        if (args.RETURNNAME === 'RETURNS') {
            alert('Select a RETURN name.');
        } else {
            return args.RETURNNAME;
        }
    }

    _getReturnsMenu() {
        if (this.returns.length === 0) {
            const returnNAME = 'RETURNS';
            this.returns.push({ name: returnNAME });
        }
        return this.returns.map(returns => [returns.name, returns.name]);
    }

    ado_GET_ACT_MODEL(args, util) {
        /*
        // Old code that didn't work when loading a project.
        const returnID = uid();
        const returnNAME = 'modelid'

        const check_return = util.target.lookupVariableByNameAndType(returnNAME, Variable.SCALAR_TYPE);
        if(!check_return) {
            this.returns.push({ id: returnID, name: returnNAME });
        }

        const modelVAR = util.target.lookupOrCreateVariable(returnID, returnNAME);
        const STAGE_INFO = this.runtime.getTargetForStage();
        modelVAR.value = STAGE_INFO.id;
        */

        // PB: Fix (in function) for restoring returns when loading project file.
        this.__addReturnIfMissing('modelid');

        if(this.gen) {
            this.gen_code.push(`${this.getIndentation()}CC "Modeling" GET_ACT_MODEL`);
        }
    }

    ado_GET_ALL_OBJS_OF_CLASSNAME(args, util) {
        /*
        // Old code that didn't work when loading a project.
        const returnID = uid();
        const returnNAME = 'objids'

        const check_return = util.target.lookupVariableByNameAndType(returnNAME, Variable.SCALAR_TYPE);

        if(!check_return) {
            this.returns.push({ id: returnID, name: returnNAME });
        }

        const returnOBJIDs = util.target.lookupOrCreateVariable(returnID, returnNAME);
        */

        // PB: Fix (in function) for restoring returns when loading project file.
        this.__addReturnIfMissing('objids');

        if(this.gen) {
            this.gen_code.push(`${this.getIndentation()}CC "Core" GET_ALL_OBJS_OF_CLASSNAME modelid:(${args.MODELID}) classname:(${args.CLASSNAME})`);
        }
    }

    ado_GET_ATTR_VAL(args, util) {
        /*
        // Old code that didn't work when loading a project.
        const returnID = uid();
        const returnNAME = 'val'

        const check_return = util.target.lookupVariableByNameAndType(returnNAME, Variable.SCALAR_TYPE);
        if(!check_return) {
            this.returns.push({ id: returnID, name: returnNAME });
        }

        const returnVAL = util.target.lookupOrCreateVariable(returnID, returnNAME);
        */

        // PB: Fix (in function) for restoring returns when loading project file.
        this.__addReturnIfMissing('val');

        if(this.gen) {
            this.gen_code.push(`${this.getIndentation()}CC "Core" GET_ATTR_VAL objid:(${args.OBJID}) attrname:(${args.ATTRNAME})`);
        }
    }

    ado_INFOBOX(args, util) {
        if(this.gen) {
            this.gen_code.push(`${this.getIndentation()}CC "AdoScript" INFOBOX (${args.STRVAL})`);
        }
    }

    ado_VIEWBOX(args, util) {
        if(this.gen) {
            this.gen_code.push(`${this.getIndentation()}CC "AdoScript" VIEWBOX text:(${args.STRVAL})`);
        }
    }

    ado_VAL(args, util) {
        return `VAL ${args.STRVAL}`;
    }

    ado_STR(args, util) {
        return `STR ${args.STRVAL}`;
    }

    ado_tokcnt(args, util) {
        return `tokcnt(${args.SOURCE})`;
    }

    //TODO This only allows to append to a one dimensional array. The original function allows to append to multi-dimensional arrays.
    ado_aappend(args, util) {
        return `aappend(${args.ARRAY}, ${args.VAL})`;
    }

    ado_START_GEN(args, util) {
        //TODO so this.gen stays true if there is no ado_GENCODE element ...
        this.gen = true;
        this.gen_code = [];
        // PB: Fix for cases where share is pressed without a ado_GENCODE block, and later a ado_GENCODE block is added and normal execution is done.
        if (util.target.generateAndShare) {
            util.target.generateAndShare = false;
            util.target.shareInsteadOfShow = true;
        } else {
            util.target.shareInsteadOfShow = false;
        }
        return true;
    }

    ado_GENCODE(args, util) {
        // PB: this approach seems overly complicated and can lead to issues down the line. Simplified it to a simple array.join('\n'). Also the separate function doesn't seem to serve any purpose.
        /*const previewAdoScriptcode = (data) => {
            const blob = new Blob([JSON.stringify(data, null, 2)
                .replace(/"/g,'')
                .replace(/\\,\\/g, 'TMAP_COMMA')
                .replace(/,(?! )/g, '')
                .replace(/TMAP_COMMA/g, '\\,\\')
                .replace(/\\/g, '"')], {
              type: 'text/plain;charset=UTF-8',
            });
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        };
        previewAdoScriptcode(this.gen_code);*/

        const blob = this.__generateCode();
        if (util.target.shareInsteadOfShow) {
            util.target.shareInsteadOfShow = false;
            // Get the URL where to upload the file(s).
            const fileName = `${util.target.projectTitle}---${util.target.getName()}.asc`;
            try {
                // Test if a valid URL is specified for Bucket link.
                new URL(util.target?.bucketLink);
                const bucketLink = (util.target.bucketLink.slice(-1) === '/') ? util.target.bucketLink : util.target.bucketLink + '/';
                // Upload the content to a Bucket file.
                var myHeaders = new Headers();
                myHeaders.append("Content-Type", "text/plain");
                var requestOptions = {
                    method: 'PUT',
                    headers: myHeaders,
                    body: blob,
                    redirect: 'follow'
                };
                fetch(`${bucketLink}${fileName}`, requestOptions);
            } catch (err) {
                alert('Specified bucket link is not a valid URL or upload has failed.\nError message:\n' + err);
            }
        } else {
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
        }
        this.gen = false;
        this.gen_code = [];
    }

    // added by muck
    // PB: Removed as the sharing is being reworked.
    /*
    ado_GENCODE_PUBLIC(args, util) {
        const blob = this.__generateCode();

        var myHeaders = new Headers();
        myHeaders.append("Content-Type", "text/plain");
        var requestOptions = {
            method: 'PUT',
            headers: myHeaders,
            body: blob,
            redirect: 'follow'
        };
        fetch("https://bucket.omilab.org/tmp/generatedAdoscript.asc", requestOptions);
    }
    */

    /*
    ado_BREAK(args) {

    }

    ado_CALL(args, util) {

    }

    ado_CC(args, util) {

    }

    ado_EXECUTE(args, util) {

    }

    ado_LEO(args, util) {

    }

    ado_NEXT(args, util) {

    }

    ado_SEND(args, util) {

    }

    ado_START(args, util) {

    }

    ado_SYSTEM(args, util) {

    }
    */
}

module.exports = Scratch3AdoScriptEx;