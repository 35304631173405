//OMiLAB Added a field to enter the bucket for sharing. This is code from `project-title-input.jsx` adapted.

import classNames from 'classnames';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import React from 'react';
import {defineMessages, intlShape, injectIntl} from 'react-intl';
import {setBucketLink} from '../../reducers/bucket-link';

import BufferedInputHOC from '../forms/buffered-input-hoc.jsx';
import Input from '../forms/input.jsx';
const BufferedInput = BufferedInputHOC(Input);

import styles from './bucket-link.css';

const messages = defineMessages({
    bucketLinkPlaceholder: {
        id: 'gui.gui.bucketLinkPlaceholder',
        description: 'Placeholder for bucket link when blank',
        defaultMessage: 'Bucket link here'
    }
});

const BucketLinkInput = ({
    className,
    intl,
    onSubmit,
    bucketLink
}) => (
    <BufferedInput
        className={classNames(styles.titleField, className)}
        maxLength="250"
        placeholder={intl.formatMessage(messages.bucketLinkPlaceholder)}
        tabIndex="0"
        type="text"
        value={bucketLink}
        onSubmit={onSubmit}
    />
);

BucketLinkInput.propTypes = {
    className: PropTypes.string,
    intl: intlShape.isRequired,
    onSubmit: PropTypes.func,
    bucketLink: PropTypes.string
};

const mapStateToProps = state => ({
    bucketLink: state.scratchGui.bucketLink
});

const mapDispatchToProps = dispatch => ({
    onSubmit: link => dispatch(setBucketLink(link))
});

export default injectIntl(connect(
    mapStateToProps,
    mapDispatchToProps
)(BucketLinkInput));
