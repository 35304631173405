//OMiLAB Added a field to enter the bucket for sharing. This is code from `project-title.js` adapted.

const SET_BUCKET_LINK = 'bucketLink/SET_BUCKET_LINK';

//TODO This is set in src/lib/titled-hoc.jsx
const initialState = '';

const reducer = function (state, action) {
    if (typeof state === 'undefined') state = initialState;
    switch (action.type) {
    case SET_BUCKET_LINK:
        return action.link;
    default:
        return state;
    }
};
const setBucketLink = link => ({
    type: SET_BUCKET_LINK,
    link: link
});

export {
    reducer as default,
    initialState as bucketLinkInitialState,
    setBucketLink
};
